import tinycolor from "tinycolor2";

export const variables = {
  normal_gap: ".75rem",
  half_gap: ".375rem",
  colors: {
    base: "#673AB7",
    background: {
      dark: "#673AB7",
      darkHover: tinycolor("#673AB7").lighten(20).toHexString(),
      secondary: {
        dark: "#cdcdcd",
      },
    },
    border: {
      dark: "#673AB7",
      baseBorder: tinycolor("#673AB7").lighten(20).toHexString(),
    },
    text: {
      light: "white",
      dark: "black",
    },
  },
  shadows: {
    normalShadow: "2px 2px 5px rgba(0, 0, 0, 0.12)",
    heavyShadow: "2px 2px 5px rgba(0, 0, 0, 0.4)",
    normalInsetShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.2)",
    normalInsetSideShadow: "inset 2px 0px 10px rgba(0, 0, 0, 0.1)",
    hoverShadow: `2px 2px 20px rgba(0, 0, 0, 0.2)`,
  },
  media: {
    smallscreen: "screen and (min-width: 1024px)",
    bigscreen: "screen and (min-width: 1441px)",
    retina:
      "screen and (-webkit-min-device-pixel-ratio: 2) and (max-resolution: 192dpi) and (orientation: landscape)",
  },
};

export const tableStyles = {
  table: { width: "100%" },
  head: {
    row: {
      background: variables.colors.background.secondary.dark,
    },
    cell: {
      textAlign: "center",
      color: variables.colors.text.dark,
      padding: variables.half_gap,
      borderBottom: `2px solid ${variables.colors.border.dark}`,
    },
  },
  body: {
    cell: {
      textAlign: "center",
      padding: variables.half_gap,
      color: variables.colors.text.dark,
    },
  },
  checkBox: {
    width: `calc(${variables.normal_gap} * 1.5)`,
    height: `calc(${variables.normal_gap} * 1.5)`,
    textAlign: "center",
  },
};

export const btnStyles = {
  btn: {
    height: "78%",
    padding: ".35em",
    textAlign: "center",
    width: "100%",
    cursor: "pointer",
  },
};

export const selectStyles = {
  select: {
    display: "grid",
    height: "78%",
    textAlign: "center",
    width: "100%",
    borderRadius: "5px",
    cursor: "pointer",
  },
  options: {
    width: "50%",
  },
};

export const modalGenericStyles = {
  "@keyframes slidein": {
    from: {
      background: "rgba(0,0,0,0)",
      backdropFilter: "blur(0px)",
    },
    to: {
      background: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(5px)",
    },
  },
  modal: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 5,
    display: "grid",
    placeContent: "center",
    background: "rgba(0,0,0,0.3)",
    backdropFilter: "blur(5px)",
    animationDuration: ".5s",
    animationName: "$slidein",
  },
  card: {
    background: "white",
    boxShadow: variables.shadows.normalShadow,
    minWidth: "30vw",
    maxWidth: "80vw",
    position: "relative",
    borderRadius: "5px",
  },
  cardHeader: {
    borderBottom: `2px solid ${variables.colors.border.baseBorder}`,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    padding: variables.normal_gap,
    gridGap: variables.normal_gap,
    fontSize: "x-large",
  },
  cardBody: {
    padding: variables.normal_gap,
    minHeight: "30vh",
    maxHeight: "70vh",
    overflow: "auto",
  },
  cardActions: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoFlow: "column",
    justifyContent: "end",
    padding: variables.normal_gap,
  },
  loader: { zIndex: 9999 },
};

export const formItemStyles = {
  display: "grid",
  gridGap: variables.half_gap,
  width: "100%",
  padding: variables.half_gap,
  justifySelf: "center",
  "& label": {
    fontSize: "small",
    fontWeight: "bold",
  },
  "& input, select": {
    padding: variables.half_gap,
  },
};
