import _ from "lodash";
import React from "react";
import Sidebar from "./components/Sidebar";
import { createUseStyles } from "react-jss";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { variables } from "./variables";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ======================= Pages =======================
import B2CAccommodationApi from "./pages/B2CAccommodationApi";

export const links = [
  {
    title: "B2C Accommodation Api",
    id: "b2c_accommodation_api",
    path: "/b2c-accommodation-api",
    element: <B2CAccommodationApi />,
    inMenu: true,
    links: [],
  },
];

const appStyles = createUseStyles({
  App: {
    display: "grid",
    gridTemplateColumns: "15rem 1fr",
    height: "100vh",
    overflow: "hidden",
  },
  mainContainer: {
    display: "grid",
    overflow: "auto",
  },
  toastContainer: {
    position: "fixed",
    bottom: 0,
  },
});
const App = () => {
  const classes = appStyles();

  return (
    <BrowserRouter>
      <div className={classes.App}>
        <Sidebar />
        <div className={classes.mainContainer}>
          <Routes>
            {links.map((link, idx) => {
              const subLinks = _.get(link, "links", []);
              const hasSubLinks = subLinks.length > 0;

              return (
                <React.Fragment key={idx}>
                  <Route key={idx} path={link.path} element={link.element} />
                  {hasSubLinks &&
                    subLinks.map((sublink, idx) => (
                      <Route
                        key={idx}
                        path={`${link.path}${sublink.path}`}
                        element={sublink.element}
                      />
                    ))}
                </React.Fragment>
              );
            })}
          </Routes>
          <div className={classes.toastContainer}>
            <ToastContainer />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
