import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";
import { links } from "../../App";
import { variables } from "../../variables";

const LinkStyle = (props) => ({
  padding: variables.normal_gap,
  cursor: "pointer",
  background: props.isActive ? variables.colors.background.darkHover : "none",
  display: "block",
  color: props.isActive
    ? variables.colors.text.dark
    : variables.colors.text.light,
  transition: "background .3s, color .3s",
  "&:hover": {
    background: variables.colors.background.darkHover,
    color: variables.colors.text.dark,
  },
});

const linkStyles = createUseStyles({
  Link: (props) => LinkStyle(props),
  linkBody: (props) => ({ display: props.shouldRender ? "block" : "none" }),
});
const Link = ({ link, isActive, isSublinkActive, onClick, children }) => {
  const classes = linkStyles({ shouldRender: isActive || isSublinkActive });
  return (
    <React.Fragment>
      <a onClick={onClick} className={classes.Link}>
        <strong>{link.title}</strong>
      </a>
      <div className={classes.linkBody}>{children}</div>
    </React.Fragment>
  );
};
Link.propTypes = {
  link: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSublinkActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const subLinkStyles = createUseStyles({
  SubLink: (props) => ({
    ...LinkStyle(props),
    boxShadow: props.isActive ? variables.shadows.normalInsetShadow : "none",
    paddingLeft: `calc(${variables.normal_gap} * 3)`,
  }),
});
const SubLink = ({ link, isActive, onClick }) => {
  const classes = subLinkStyles({ isActive });
  return (
    <a onClick={onClick} className={classes.SubLink}>
      <strong>{link.title}</strong>
    </a>
  );
};
SubLink.propTypes = {
  link: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const sidebarStyles = createUseStyles({
  Sidebar: {
    display: "grid",
    gridTemplateRows: "3rem 1fr auto",
    backgroundColor: variables.colors.background.dark,
    padding: [variables.normal_gap, 0],
    boxShadow: variables.shadows.normal,
    zIndex: 1,
    overflowY: "hidden",
  },
  header: {
    textAlign: "center",
    color: variables.colors.text.light,
  },
  links: {
    overflow: "auto",
  },
  subLinks: {
    boxShadow: variables.shadows.normalInsetShadow,
    background: variables.colors.background.secondary.dark,
    color: variables.colors.text.dark,
    display: "grid",
  },
  logInBtn: {
    display: "grid",
  },
});
const Sidebar = () => {
  const [activeId, setActiveId] = useState(null);

  const navigate = useNavigate();

  const classes = sidebarStyles();

  return (
    <div className={classes.Sidebar}>
      <div className={classes.header} onClick={() => navigate("/")}>
        <h2>easyTravel.tech APIs</h2>
      </div>
      <div className={classes.links}>
        {links
          .filter((link) => link.inMenu === true)
          .map((link, idx) => {
            const sublinks = _.get(link, "links", []);
            const isSublinkActive = sublinks.some(
              (sublink) => sublink.id === activeId
            );

            return (
              <React.Fragment key={idx}>
                <Link
                  key={idx}
                  link={link}
                  isActive={link.id === activeId}
                  isSublinkActive={isSublinkActive}
                  onClick={() => {
                    setActiveId(link.id);
                    navigate(link.path);
                  }}
                >
                  {sublinks.length > 0 && (
                    <div className={classes.subLinks}>
                      {sublinks
                        .filter((sublink) => sublink.inMenu === true)
                        .map((sublink, jidx) => (
                          <SubLink
                            key={jidx}
                            link={sublink}
                            isActive={sublink.id === activeId}
                            onClick={() => {
                              setActiveId(sublink.id);
                              navigate(`${link.path}${sublink.path}`);
                            }}
                          />
                        ))}
                    </div>
                  )}
                </Link>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default Sidebar;
